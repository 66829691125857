<template>
  <div>
    <div>
      <v-card flat class="rounded-max" width="550px">
        <div
          :class="
            person.jenis_kelamin == 'laki-laki'
              ? 'blue'
              : person.jenis_kelamin == 'perempuan'
              ? 'pink'
              : 'grey'
          "
          style="height: 100px"
        ></div>
        <div class="d-flex justify-center">
          <div class="temp-ava">
            <img
              src="../../assets/img/IconProfile Default250px.png"
              class="ava"
              alt=""
            />
          </div>
        </div>
        <div class="px-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="text-center"
                v-model="account.nama_lengkap"
                hide-details="auto"
                label="Full Name"
                background-color="#e3eeea"
                solo
                flat
                rounded
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="text-center"
                v-model="account.email"
                hide-details="auto"
                label="Email"
                background-color="#e3eeea"
                prepend-inner-icon="mdi-email"
                solo
                flat
                rounded
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="text-center"
                v-model="person.jabatan"
                hide-details="auto"
                label="Jabatan"
                background-color="#e3eeea"
                prepend-inner-icon="mdi-briefcase-variant"
                solo
                flat
                rounded
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="text-center"
                v-model="person.no_hp"
                hide-details="auto"
                label="No Hp"
                background-color="#e3eeea"
                prepend-inner-icon="mdi-cellphone-iphone"
                solo
                flat
                rounded
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="calendar1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="person.tanggal_lahir"
                    label="Tanggal Lahir"
                    prepend-inner-icon="mdi-calendar"
                    background-color="#e3eeea"
                    rounded
                    solo
                    flat
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="person.tanggal_lahir"
                  @input="calendar1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="pil d-flex justify-center">
                <v-radio-group
                  v-model="person.jenis_kelamin"
                  row
                  hide-details="auto"
                  class="mt-0"
                >
                  <v-radio label="Laki-laki" value="laki-laki"></v-radio>
                  <v-radio label="Perempuan" value="perempuan"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                background-color="#e3eeea"
                prepend-inner-icon="mdi-map-marker"
                v-model="person.alamat"
                hide-details
                solo
                flat
                rounded
                required
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <div class="pa-3">
          <v-btn
            block
            rounded
            depressed
            color="green"
            class="mt-5 text-capitalize"
            v-if="!loading"
            @click="updateItem"
            dark
          >
            Update Profile
          </v-btn>
          <v-btn
            block
            rounded
            depressed
            color="green"
            class="mt-5 text-capitalize"
            v-if="loading"
            dark
          >
            <v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading
          </v-btn>
        </div>
      </v-card>
      <v-card class="rounded-max mt-7 pa-3" flat width="550px">
        <div class="d-flex justify-center">
          <v-icon class="mr-2">mdi-lock-reset</v-icon>
          <h3>Change Password</h3>
        </div>
        <div class="mt-2">
          <v-alert dense text type="warning" v-if="!regex">
            Password Must contain: Lowercase, Uppercase, Numbers & Special
            Characters
          </v-alert>
          <v-alert dense text type="error" v-if="regex">
            {{ regex }}
          </v-alert>
        </div>
        <v-row class="mt-3">
          <v-col cols="4">Password</v-col>
          <v-col cols="8">
            <v-text-field
              v-model="pwd"
              hide-details="auto"
              :error-messages="pwdErr"
              label="Password"
              background-color="#e3eeea"
              solo
              flat
              rounded
              required
              dense
              @input="$v.pwd.$touch()"
              @blur="$v.pwd.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Confirm Password</v-col>
          <v-col cols="8">
            <v-text-field
              v-model="repwd"
              hide-details="auto"
              label=" Confirm Password"
              background-color="#e3eeea"
              :error-messages="repwdErr"
              solo
              flat
              rounded
              required
              dense
              @input="$v.repwd.$touch()"
              @blur="$v.repwd.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          block
          rounded
          depressed
          color="green"
          class="mt-5"
          v-if="!loadingPwd"
          @click="updatePwd"
          dark
        >
          Submit
        </v-btn>
        <v-btn
          block
          rounded
          depressed
          color="green"
          class="mt-5 text-capitalize"
          v-if="loadingPwd"
          dark
        >
          <v-progress-circular
            :size="25"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          Loading
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "updateTf",
  props: ["account", "person", "loading", "loadingPwd", "regex"],
  mixins: [validationMixin],
  validations: {
    pwd: { required, minLength: minLength(8) },
    repwd: { required, sameAsPassword: sameAs("pwd") },
  },
  computed: {
    pwdErr() {
      const errors = [];
      if (!this.$v.pwd.$dirty) return errors;
      !this.$v.pwd.minLength && errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.pwd.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    repwdErr() {
      const errors = [];
      if (!this.$v.repwd.$dirty) return errors;
      !this.$v.repwd.sameAsPassword && errors.push("kata sandi tidak sama!");
      !this.$v.repwd.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  data() {
    return {
      calendar1: false,
      pwd: "",
      repwd: "",
      isPasswordUpdate: false,
    };
  },
  methods: {
    updateItem() {
      let body = {
        id: this.account.id,
        nama_lengkap: this.account.nama_lengkap,
        no_hp: this.person.no_hp,
        tanggal_lahir: this.person.tanggal_lahir,
        jenis_kelamin: this.person.jenis_kelamin,
        alamat: this.person.alamat,
        jabatan: this.person.jabatan,
      };
      this.$emit("update", body);
    },
    updatePwd() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          id: this.account.id,
          password: this.pwd,
        };
        this.$emit("pwd", data);
      }
    },
  },
};
</script>

<style scoped>
.ava {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.temp-ava {
  /* padding: 5px; */
  background: white;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -70px;
}
.pil {
  padding: 5px;
  border-radius: 20px;
  background: #e3eeea;
}
</style>