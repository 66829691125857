<template>
  <div>
    <div>
      <v-card flat class="rounded-max" width="550px">
        <div
          :class="
            person.jenis_kelamin == 'Laki-laki'
              ? 'blue'
              : person.jenis_kelamin == 'Perempuan'
              ? 'pink'
              : 'grey'
          "
          style="height: 100px"
        ></div>
        <div class="d-flex justify-center">
          <div class="temp-ava">
            <img
              src="../../assets/img/IconProfile Default250px.png"
              class="ava"
              alt=""
            />
          </div>
        </div>
        <div class="px-5">
          <v-row>
            <v-col cols="12">
              <div class="pil text-center">
                {{ account.nama_lengkap }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="pil px-5">
                <v-icon>mdi-email</v-icon> {{ account.email }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="pil px-5">
                <v-icon>mdi-account</v-icon> {{ person.no_tf }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="pil px-5">
                <v-icon>mdi-cellphone-iphone</v-icon> {{ person.no_hp }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="pil px-5">
                <v-icon>mdi-calendar</v-icon> {{ person.tgl_lahir }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="pil d-flex justify-center">
                <v-radio-group
                  disabled
                  v-model="person.jenis_kelamin"
                  row
                  hide-details="auto"
                  class="mt-0"
                >
                  <v-radio label="Laki-laki" value="Laki-laki"></v-radio>
                  <v-radio label="Perempuan" value="Perempuan"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="pa-1">
              <p class="text-center small-p ma-1">Tahun TF</p>
              <div class="pil px-5 text-center">
                {{ person.tahun_tf ? person.tahun_tf : "-" }}
              </div>
            </v-col>
            <v-col cols="4" class="pa-1">
              <p class="text-center small-p ma-1">Program</p>
              <div class="pil px-5 text-center">
                {{ person.program ? person.program : "-" }}
              </div>
            </v-col>
            <v-col cols="5" class="pa-1">
              <p class="text-center small-p ma-1">Assessment</p>
              <div class="pil px-5 text-center">
                {{ person.assesment ? person.assesment : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-1">
              <p class="text-center small-p ma-1">Universitas</p>
              <div class="pil px-5 text-center">
                {{ person.universitas ? person.universitas : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-1">NIM</p>
              <div class="pil px-5 text-center">
                {{ person.nim ? person.nim : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-1">NIK</p>
              <div class="pil px-5 text-center">
                {{ person.nik ? person.nik : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Jurusan</p>
              <div class="pil px-5 text-center">
                {{ person.jurusan ? person.jurusan : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Fakultas</p>
              <div class="pil px-5 text-center">
                {{ person.grouping_fakultas ? person.grouping_fakultas : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Grade</p>
              <div class="pil px-5 text-center">
                {{ person.grade ? person.grade : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Tahun PTN</p>
              <div class="pil px-5 text-center">
                {{ person.tahun_ptn ? person.tahun_ptn : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester Daftar</p>
              <div class="pil px-5 text-center">
                {{ person.semester_daftar ? person.semester_daftar : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester Sekarang</p>
              <div class="pil px-5 text-center">
                {{ person.semester_sekarang ? person.semester_sekarang : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 1</p>
              <div class="pil px-5 text-center">
                {{ person.semester1 ? person.semester1 : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 2</p>
              <div class="pil px-5 text-center">
                {{ person.semester2 ? person.semester2 : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 3</p>
              <div class="pil px-5 text-center">
                {{ person.semester3 ? person.semester3 : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 4</p>
              <div class="pil px-5 text-center">
                {{ person.semester4 ? person.semester4 : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 5</p>
              <div class="pil px-5 text-center">
                {{ person.semester5 ? person.semester5 : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 6</p>
              <div class="pil px-5 text-center">
                {{ person.semester6 ? person.semester6 : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 7</p>
              <div class="pil px-5 text-center">
                {{ person.semester7 ? person.semester7 : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Semester 8</p>
              <div class="pil px-5 text-center">
                {{ person.semester8 ? person.semester8 : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Provinsi</p>
              <div class="pil px-5 text-center">
                {{ person.provinsi ? person.provinsi : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Kota</p>
              <div class="pil px-5 text-center">
                {{ person.kota ? person.kota : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Tempat Lahir</p>
              <div class="pil px-5 text-center">
                {{ person.tempat_lahir ? person.tempat_lahir : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Kode Pos</p>
              <div class="pil px-5 text-center">
                {{ person.kode_pos ? person.kode_pos : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="pil px-5 text-center">
                <v-icon>mdi-map-marker</v-icon> {{ person.alamat }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Agama</p>
              <div class="pil px-5 text-center">
                {{ person.agama ? person.agama : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Etnis</p>
              <div class="pil px-5 text-center">
                {{ person.etnis ? person.etnis : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-1">
              <p class="text-center small-p ma-0">Nama Bank</p>
              <div class="pil px-5 text-center">
                {{ person.nama_bank ? person.nama_bank : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Nama Rekening</p>
              <div class="pil px-5 text-center">
                {{ person.nama_rekening ? person.nama_rekening : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">No Rekening</p>
              <div class="pil px-5 text-center">
                {{ person.no_rekening ? person.no_rekening : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Jumlah Tunjangan Bulanan</p>
              <div class="pil px-5 text-center">
                {{
                  person.jumlah_tunjangan_bulanan
                    ? person.jumlah_tunjangan_bulanan
                    : "-"
                }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Tagihan Ukt</p>
              <div class="pil px-5 text-center">
                {{ person.tagihan_ukt ? person.tagihan_ukt : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Ukuran Kaos</p>
              <div class="pil px-5 text-center">
                {{ person.ukuran_kaos ? person.ukuran_kaos : "-" }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-1">
              <p class="text-center small-p ma-0">Tsg</p>
              <div class="pil px-5 text-center">
                {{ person.tsg ? person.tsg : "-" }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card class="rounded-max mt-7 pa-3" flat width="550px">
        <div class="d-flex justify-center">
          <v-icon class="mr-2">mdi-lock-reset</v-icon>
          <h3>Change Password</h3>
        </div>
        <div class="mt-2">
          <v-alert dense text type="warning" v-if="!regex">
            Password Must contain: Lowercase, Uppercase, Numbers & Special
            Characters
          </v-alert>
          <v-alert dense text type="error" v-if="regex">
            {{ regex }}
          </v-alert>
        </div>
        <v-row class="mt-3">
          <v-col cols="4">Password</v-col>
          <v-col cols="8">
            <v-text-field
              v-model="pwd"
              hide-details="auto"
              :error-messages="pwdErr"
              label="Password"
              background-color="#e3eeea"
              solo
              flat
              rounded
              required
              dense
              @input="$v.pwd.$touch()"
              @blur="$v.pwd.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Confirm Password</v-col>
          <v-col cols="8">
            <v-text-field
              v-model="repwd"
              hide-details="auto"
              label=" Confirm Password"
              background-color="#e3eeea"
              :error-messages="repwdErr"
              solo
              flat
              rounded
              required
              dense
              @input="$v.repwd.$touch()"
              @blur="$v.repwd.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          block
          rounded
          depressed
          color="green"
          class="mt-5"
          v-if="!loadingPwd"
          @click="updatePwd"
          dark
        >
          Submit
        </v-btn>
        <v-btn
          block
          rounded
          depressed
          color="green"
          class="mt-5 text-capitalize"
          v-if="loadingPwd"
          dark
        >
          <v-progress-circular
            :size="25"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          Loading
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "updateScholar",
  props: ["account", "person", "loading", "loadingPwd","regex"],
  mixins: [validationMixin],
  validations: {
    pwd: { required, minLength: minLength(8) },
    repwd: { required, sameAsPassword: sameAs("pwd") },
  },
  computed: {
    pwdErr() {
      const errors = [];
      if (!this.$v.pwd.$dirty) return errors;
      !this.$v.pwd.minLength && errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.pwd.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    repwdErr() {
      const errors = [];
      if (!this.$v.repwd.$dirty) return errors;
      !this.$v.repwd.sameAsPassword && errors.push("kata sandi tidak sama!");
      !this.$v.repwd.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  data() {
    return {
      calendar1: false,
      pwd: "",
      repwd: "",
      isPasswordUpdate: false,
    };
  },
  methods: {
    // updateItem() {
    //   let body = {
    //     id: this.account.id,
    //     nama_lengkap: this.account.nama_lengkap,
    //     no_hp: this.person.no_hp,
    //     tgl_lahir: this.person.tanggal_lahir,
    //     jenis_kelamin: this.person.jenis_kelamin,
    //     alamat: this.person.alamat,
    //     nim: this.person.nim,
    //     no_tf: this.person.no_tf,
    //     pendidikan_terakhir: this.person.pendidikan_terakhir,
    //     tujuan: this.person.tujuan,
    //     fakultas: this.person.fakultas,
    //     jurusan: this.person.jurusan,
    //     universitas: this.person.universitas,
    //   };
    //   this.$emit("update", body);
    // },
    updatePwd() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          id: this.account.id,
          password: this.pwd,
        };
        this.$emit("pwd", data);
      }
    },
  },
};
</script>

<style scoped>
.ava {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.temp-ava {
  /* padding: 5px; */
  background: white;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -70px;
}
.pil {
  padding: 5px;
  border-radius: 20px;
  background: #e3eeea;
}
.small-p {
  font-size: small;
}
</style>
