<template>
  <div class="pa-3 d-flex justify-center">
    <section class="_70 d-flex justify-center align-center" v-if="!loadingPage">
      <div style="margin-left: 55px" class="pa-3" v-if="user && person">
        <ProfileAdmin
          v-bind:account="user.data[0]"
          v-bind:person="person"
          v-bind:loadingPwd="loadingPwd"
          v-bind:loading="loading"
          v-bind:regex="regex"
          v-if="
            $route.params.role == 'data_control' ||
            $route.params.role == 'superadmin'
          "
          @update="updateItem"
          @pwd="upatePwd"
        />
        <ProfileCounselor
          v-bind:account="user.data[0]"
          v-bind:person="person"
          v-bind:loadingPwd="loadingPwd"
          v-bind:loading="loading"
          v-bind:regex="regex"
          v-if="$route.params.role == 'admin_assessment'"
          @update="updateItem"
          @pwd="upatePwd"
        />
        <ProfileTf
          v-bind:account="user.data[0]"
          v-bind:person="person"
          v-bind:loadingPwd="loadingPwd"
          v-bind:loading="loading"
          v-bind:regex="regex"
          v-if="$route.params.role == 'tf'"
          @update="updateItem"
          @pwd="upatePwd"
        />
        <profile-scholar
          v-bind:account="user.data[0]"
          v-bind:person="person"
          v-bind:loading="loading"
          v-bind:loadingPwd="loadingPwd"
          v-bind:regex="regex"
          v-if="$route.params.role == 'scholar'"
          @update="updateItem"
          @pwd="upatePwd"
        />
      </div>
    </section>
    <div v-else class="pa-3">
      <LoadingLG />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import ProfileAdmin from "../../components/Profile/ProfileAdmin.vue";
import LoadingLG from "../../components/etc/loading-component/loadingLG.vue";
import ProfileCounselor from "../../components/Profile/profileCounselor.vue";
import ProfileTf from "../../components/Profile/profileTf.vue";
import ProfileScholar from "../../components/Profile/profileScholar.vue";
import Cookies from "js-cookie";

export default {
  components: {
    ProfileAdmin,
    LoadingLG,
    ProfileCounselor,
    ProfileTf,
    ProfileScholar,
  },
  name: "profile",
  computed: {
    ...mapState({
      id: (state) => state.id,
      user: (state) => state.user.list_user,
    }),
  },
  data() {
    return {
      person: null,
      loading: false,
      loadingPage: false,
      loadingPwd: false,
      route: null,
      include: "",
      regex: '',
    };
  },
  mounted() {
    this.route = this.$route.params.role;
    switch (this.route) {
      case "data_control":
        this.label = "Data Control";
        this.include = "dataControl";
        break;
      case "tf":
        this.label = "Admin TF";
        this.include = "tf";
        break;
      case "admin_assessment":
        this.label = "Admin Assessment";
        this.include = "adminAssessment";
        break;
      case "scholar":
        this.label = "Scholar";
        this.include = "scholar";
        break;
    }
    this.getUser();
  },
  methods: {
    getUser() {
      this.loadingPage = true;
      let params = {
        id: this.id,
        role: this.route,
        include: this.include,
      };
      this.$store.dispatch("user/listUser", params).then((data) => {
        this.person = data.data[0].relationships[0][this.include];
        setTimeout(() => (this.loadingPage = false), 1000);
      });
    },
    upatePwd(body) {
      this.loadingPwd = true;
      this.$store.dispatch("user/updateAccount", body).then((data) => {

        this.loadingPwd = false;
        if (data.status == "success") {
          this.Swal("success", data.message);
          setTimeout(() => {
            this.logOut();
          }, 800);
        } else {
          // console.log(data.data.errors.password);
          this.regex = data.data.errors.password[0]
          this.Swal("error", data.message);
        }
      });
    },
    updateItem(body) {
      let role = this.route;
      let data = {
        role: role,
        body: body,
      };
      this.loading = true;
      this.$store
        .dispatch("user/updateUser", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.Swal("success", data.message);
          } else {
            this.Swal("error", data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    logOut() {
      Cookies.remove("token");
      this.$router.go("/login");
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top",
        toast: true,
        timer: 5000,
      });
    },
  },
};
</script>

<style scoped>
._70 {
  width: 70%;
  /* margin-top: 30px; */
}
.ava {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.temp-ava {
  /* padding: 5px; */
  background: white;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -70px;
}
</style>